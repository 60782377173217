/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

:root {
    .toolbar-background {
        --ion-background-color: var(--glassfactory-gold);
    }
}
ion-searchbar {
    .searchbar-input-container {
        input{
            border-radius: 5px !important;
            padding: 15px !important;
            margin: 5px !important;
            width: calc(100% - 10px) !important;
            box-shadow: 0px 1px 5px 2px #b69653 !important;
        }
    }
}
.searchbar-search-icon.sc-ion-searchbar-md { 
    display:none; 
    width:0; 
    height:0; 
}
ion-toggle {
    --background: #ccc;
    --background-checked: #b69653;
    --handle-background: #fff;
    --handle-background-checked: #fff;
    --border-radius: 50px;
    --handle-height: 35px;
    --handle-width: 35px;
    --handle-border-radius: 50px;
    width: 100px;
    height: 40px;
}
.header-translucent-ios ion-toolbar ion-title {
    padding: 0 15px !important;
}
ion-label {
    font-weight: 600;
}
.search-icon {
    transform: scaleX(-1);
}

.acknowledgement .alert-wrapper {
    min-height: 250px !important;
}
.acknowledgement .alert-sub-title {
    font-size: 15px !important;
    font-style: italic !important;
    margin-top: 10px !important;
    position: absolute;
    bottom: 60px;
}
.acknowledgement .alert-button-group {
    position: absolute;
    bottom: 0;
}

/* iOS specific */
.ios .form-validation ion-toggle {
    margin-left: 15px;
}
.sc-ion-searchbar-ios-h {
    height: 80px !important;
}
.searchbar-input-container.sc-ion-searchbar-ios {
    height: 50px !important;
}
.searchbar-input-container.sc-ion-searchbar-ios input {
    background: white !important;
}
.header-translucent-ios ion-toolbar ion-title,
.alert-title.sc-ion-alert-ios,
.ios .form-validation .title,
.ios --ion-default-font,
.ios .content p,
.ios ion-label,
.ios ion-title,
.ios .greeting,
.ios input,
.ios h2,
.ios h3,
.ios p {
    font-family: "Roboto", sans-serif !important;
}
.header-translucent-ios ion-toolbar ion-title,
.ios .greeting {
    text-align: left;
}
.searchbar-clear-icon.sc-ion-searchbar-ios {
    display: none;
}
ion-tab-bar {
    overflow: auto;
    padding-left: 10px;
    ion-tab-button {
        // min-width: 70px;
    }
}
.back {
    position: fixed;
    bottom: 80px;
    left: 15px;
    height: 60px;
    width: 60px;
    --border-radius: 50px;
    --background: #b69653 !important;
    --color: white !important;
    z-index: 999;
    font-size: 14px;
}